
export function formatDate (date, short) {
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric', month: short ? 'short' : 'long', day: 'numeric'
  })
}

export function formatAgo (date, full) {
  const published = new Date(date)
  const seconds = Math.floor((new Date() - published) / 1000)
  let interval = Math.floor(seconds / 43200)
  if (interval > 1) {
    return published.toLocaleDateString('en-US', {
      year: 'numeric', month: 'short', day: 'numeric'
    })
  }
  interval = Math.floor(seconds / 3600)
  if (interval >= 1) {
    return interval + 'h' + (full ? ' ago' : '')
  }
  interval = Math.floor(seconds / 60)
  if (interval > 1) {
    return interval + 'm' + (full ? ' ago' : '')
  }
  return Math.floor(seconds) + 's' + (full ? ' ago' : '')
}
